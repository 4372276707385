import React from "react"
import { ParallaxProvider } from 'react-scroll-parallax'

import Layout from "../components/layout"
import SEO from "../components/seo"

import "./home.css"

import Elements from "./home/Elements"
import Hero from "./home/Hero"
import CreateLinks from "./home/CreateLinks"
import SendLinks from "./home/SendLinks"
import CreateReminder from "./home/CreateReminder"
import ReceiveFast from "./home/ReceiveFast"
import PaymentMethods from "./home/PaymentMethods"
import AboutTratto from "./home/AboutTratto"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <ParallaxProvider>
      <Elements />
      <Hero />
      <CreateLinks />
      <SendLinks />
      <CreateReminder />
      <ReceiveFast />
      <PaymentMethods />
      <AboutTratto />
    </ParallaxProvider>
  </Layout>
)

export default IndexPage
